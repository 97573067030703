import { generateLocale } from './generate';

export default generateLocale([
  'ニックネーム',
  '3バイト以上のニックネームをご入力ください.',
  'メールアドレス',
  'メールアドレスをご確認ください.',
  'サイト',
  'オプション',
  'ここにコメント',
  'コメントしましょう~',
  '提出する',
  '返信する',
  'キャンセル',
  'コメント',
  '更新',
  'さらに読み込む',
  'プレビュー',
  '絵文字',
  '画像をアップロード',
  '秒前',
  '分前',
  '時間前',
  '日前',
  'たっだ今',
  'アップロード',
  'ログインする',
  'ログアウト',
  '管理者',
  'トップに置く',
  'ワード',
  'コメントは $0 から $1 ワードの間でなければなりません!\n 現在の単語番号: $2',
  '匿名',
]);
