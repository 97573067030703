import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "vcard" }
const _hoisted_4 = { class: "vhead" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 1,
  class: "vnick"
}
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["title"]
const _hoisted_11 = {
  class: "vmeta",
  "aria-hidden": "true"
}
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "vreply-wrapper"
}
const _hoisted_16 = {
  key: 1,
  class: "vquote"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerifiedIcon = _resolveComponent("VerifiedIcon")!
  const _component_ReplyIcon = _resolveComponent("ReplyIcon")!
  const _component_CommentBox = _resolveComponent("CommentBox")!
  const _component_CommentCard = _resolveComponent("CommentCard", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: "vitem",
    id: _ctx.comment.objectId
  }, [
    (_ctx.avatar)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "vuser",
          "aria-hidden": "true",
          src: _ctx.avatar
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.link)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "vnick",
              href: _ctx.link,
              target: "_blank",
              rel: "nofollow noreferrer"
            }, [
              _createTextVNode(_toDisplayString(_ctx.comment.nick), 1 /* TEXT */),
              (_ctx.comment.type)
                ? (_openBlock(), _createBlock(_component_VerifiedIcon, { key: 0 }))
                : _createCommentVNode("v-if", true)
            ], 8 /* PROPS */, _hoisted_5))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.comment.nick), 1 /* TEXT */),
              (_ctx.comment.type)
                ? (_openBlock(), _createBlock(_component_VerifiedIcon, { key: 0 }))
                : _createCommentVNode("v-if", true)
            ])),
        (_ctx.comment.type === 'administrator')
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              class: "vbadge",
              textContent: _toDisplayString(_ctx.locale.admin)
            }, null, 8 /* PROPS */, _hoisted_7))
          : _createCommentVNode("v-if", true),
        (_ctx.comment.sticky)
          ? (_openBlock(), _createElementBlock("span", {
              key: 3,
              class: "vbadge",
              textContent: _toDisplayString(_ctx.locale.sticky)
            }, null, 8 /* PROPS */, _hoisted_8))
          : _createCommentVNode("v-if", true),
        _createElementVNode("span", {
          class: "vtime",
          textContent: _toDisplayString(_ctx.timeAgo(_ctx.comment.insertedAt, _ctx.locale))
        }, null, 8 /* PROPS */, _hoisted_9),
        _createElementVNode("button", {
          class: _normalizeClass(["vreply", { active: _ctx.isReplyingCurrent }]),
          title: _ctx.isReplyingCurrent ? _ctx.locale.cancelReply : _ctx.locale.reply,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reply', _ctx.isReplyingCurrent ? null : _ctx.comment)))
        }, [
          _createVNode(_component_ReplyIcon)
        ], 10 /* CLASS, PROPS */, _hoisted_10)
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.comment.browser)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              textContent: _toDisplayString(_ctx.comment.browser)
            }, null, 8 /* PROPS */, _hoisted_12))
          : _createCommentVNode("v-if", true),
        (_ctx.comment.os)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              textContent: _toDisplayString(_ctx.comment.os)
            }, null, 8 /* PROPS */, _hoisted_13))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", {
        class: "vcontent",
        innerHTML: _ctx.comment.comment
      }, null, 8 /* PROPS */, _hoisted_14),
      (_ctx.isReplyingCurrent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_CommentBox, {
              replyId: _ctx.comment.objectId,
              replyUser: _ctx.comment.nick,
              rootId: _ctx.rootId,
              onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submit', $event))),
              onCancelReply: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('reply', null)))
            }, null, 8 /* PROPS */, ["replyId", "replyUser", "rootId"])
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.comment.children)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comment.children, (child) => {
              return (_openBlock(), _createBlock(_component_CommentCard, {
                key: child.objectId,
                comment: child,
                reply: _ctx.reply,
                rootId: _ctx.rootId,
                onReply: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('reply', $event))),
                onSubmit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('submit', $event)))
              }, null, 8 /* PROPS */, ["comment", "reply", "rootId"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 8 /* PROPS */, _hoisted_1))
}